<template>
  <transition name="fade-slide-left">
    <div
      v-if="toastActiveState"
      class="toast"
    >
      <div :class="['toast__status-layer', toastType]"></div>
      <div class="toast__status-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="toastType === 'success'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g filter="url(#filter0_d_0_1)"><ellipse rx="7.153" ry="7.2" transform="matrix(-1 0 0 1 8 8)" fill="#0E6800"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M7.2 10.2a.37.37 0 01-.517 0L4.622 8.195a.522.522 0 010-.753l.258-.252a.559.559 0 01.774 0l1.288 1.252 3.479-3.382a.559.559 0 01.774 0l.258.25a.522.522 0 010 .754L7.2 10.2z" fill="#fff"/><defs><filter id="filter0_d_0_1" x=".801" y=".801" width="64.306" height="64.4" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feMorphology radius="5" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_0_1"/><feOffset dx="6" dy="8"/><feGaussianBlur stdDeviation="10"/><feColorMatrix values="0 0 0 0 0.7 0 0 0 0 0.678767 0 0 0 0 0.624167 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/></filter></defs></svg>
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="toastType === 'warning'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.823 12.031L9.157.656c-.51-.875-1.798-.875-2.312 0L.183 12.031C-.33 12.903.309 14 1.336 14h13.332c1.024 0 1.664-1.094 1.155-1.969zM7.25 4.25a.75.75 0 011.5 0v4c0 .414-.336.75-.722.75-.386 0-.778-.334-.778-.75v-4zM8 12a.983.983 0 110-1.965.983.983 0 010 1.965z" fill="#FFBD00"/></svg>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="toastType === 'error'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm.012 11.12c-.572 0-1.052.474-1.052 1.04 0 .566.48 1.04 1.052 1.04.551 0 1.052-.474 1.027-1.015a1.02 1.02 0 00-1.027-1.065zM6.96 4.142c0-.628.305-1.145.795-1.308.49-.134.98.139 1.19.676.07.191.095.383.095.599-.012.3-.03.6-.047.899-.018.3-.035.599-.048.898-.022.463-.046.926-.07 1.388l-.07 1.388c-.024.302-.024.575-.024.872-.021.494-.35.873-.77.873-.42 0-.746-.355-.77-.844l-.106-2.15-.105-2.15-.035-.57-.035-.57z" fill="#BC0000"/></svg>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8-8-3.589-8-8zm7.26-3.266l-.009-.01a.483.483 0 01-.115-.32v-.692c0-.12.037-.23.115-.322l.004-.004.005-.005a.456.456 0 01.33-.136h.807c.127 0 .24.046.33.136.09.09.137.204.137.331v.691c0 .128-.046.24-.136.331l-.005.004-.004.004a.483.483 0 01-.322.116H7.59a.483.483 0 01-.321-.116l-.01-.008zm.102 7.898l-.008-.01a.483.483 0 01-.116-.321V6.234c0-.12.038-.232.124-.318a.456.456 0 01.33-.137h.615c.124 0 .235.044.318.136a.416.416 0 01.137.319V12.3c0 .127-.047.24-.137.33a.436.436 0 01-.318.124h-.614a.483.483 0 01-.322-.115l-.01-.008z" fill="#2A2D52"/></svg>
      </div>
      <div class="toast__message">
        <h1 class="toast__message--heading">{{ toastMessage.title }}</h1>
        <div v-html="toastMessage.description" class="toast__message--description"></div>
      </div>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:id="'global-toast-close-icon'" v-bind:class="'toast__close-icon'" v-bind:svg-inline="''" @click="hideGlobalToast" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7 .6C3.48.6.6 3.48.6 7c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4C13.4 3.48 10.52.6 7 .6zm3.2 8.704l-.896.896L7 7.896 4.696 10.2 3.8 9.304 6.104 7 3.8 4.696l.896-.896L7 6.104 9.304 3.8l.896.896L7.896 7 10.2 9.304z" fill="#AAABBA"/></svg>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  watch: {
    $route () {
      if (this.toastActiveState) {
        this.hideGlobalToast()
      }
    }
  },
  computed: {
    ...mapGetters({
      toastActiveState: "toast/getToastActiveState",
      toastMessage: "toast/getToastMessage",
      toastType: "toast/getToastType"
    })
  },
  beforeDestroy () {
    this.hideGlobalToast() // This is done because the global toast wasn't closing on Logout, even though route changed
  }
}
</script>
