<template>
  <div class="container p-0">
    <div class="mx-auto text-center">
      <div class="pt-3 label">{{ staticText.logoutLabel }}</div>

      <p class="mb-4">{{ staticText.displayMessage }}</p>

      <div class="col-md-12 mx-auto">
        <div class="row mx-auto text-center">
          <div class="col">
            <b-btn
              variant="secondary"
              @click.prevent="signOut"
              class="font-size-14 logout-buttons-size"
            >
              {{ staticText.logoutBtn }}
            </b-btn>
          </div>

          <div class="col">
            <b-btn
              variant="primary"
              @click="$emit('cancelled')"
              class="font-size-14 logout-buttons-size"
            >
              {{ staticText.cancelBtn }}
            </b-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { clearStorage } from "@/utils/authenticationManager"

export default {
  name: "SignoutModal",
  data () {
    return {
      logoutUrl: "/api/users/logout",
      staticTextDefault: {
        logoutLabel: "Logout",
        displayMessage: "Are you sure you want to log out?",
        logoutBtn: "Log out",
        cancelBtn: "Cancel"
      }
    }
  },
  methods: {
    clearStorage,
    signOut () {
      let logoutMessage = null
      axios
        .get("/api/users/logout")
        .then(response => {
          logoutMessage = response.data.message
        })
        .catch(error => {
          console.debug(error)
        })
        .finally(() => {
          this.clearStorage()
          const loginPathRouterAttr = { path: "/login" }
          if (logoutMessage) {
            loginPathRouterAttr.query = { message: logoutMessage }
          }
          this.$router.push(loginPathRouterAttr)
        })
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 8px;
}
.logout-buttons-size {
  padding: 12px 24px;
}
</style>
