<template>
  <div @click="$emit('click')">
    <div class="mb-2">
      <main-menu-help-button>
        <div
          id="help-202012240955"
          class="d-flex justify-content-center"
        >
          <div :class="['d-flex align-items-center menu-router-link', isNavMenuOpened ? 'router-link w-100': 'justify-content-center p-3']">

            <div class="navbar-icon">
              <img src="../../../public/img/icons/help-icon.svg"/>
            </div>

            <div v-if="isNavMenuOpened"
                 class="text-left labelText ml-2"
            >
              {{ staticText.helpLabel }}
            </div>

          </div>
        </div>

        <b-tooltip
          :title="staticText.helpLabel"
          :boundary-padding="20"
          :disabled="isNavMenuOpened"
          :delay="0"
          target="help-202012240955"
          placement="right"
        ></b-tooltip>

      </main-menu-help-button>
    </div>

    <error-modal v-if="errors"
                 v-model="errors"
                 :errors="[errors]"
                 :launchErrorModal="true"
                 class="mb-2 d-flex align-items-center justify-content-center"
                 :extra-class="!isNavMenuOpened ? 'px-2' : ''"
                 error-header-text=""
                 @Done="updateErrors()"
    ></error-modal>

    <div
      v-if="isGlobalAdmin"
      class="mb-2"
    >
      <treeselect id="tenantSelection"
                  class="mb-3"
                  v-model="selectedTenant"
                  :options="user.permissions.tenants"
                  :normalizer="normalizer"
                  placeholder="Select tenant"
                  value-format="Object"
                  search-nested
      ></treeselect>
    </div>

    <div
      class=" "
      :class="['bao-nav-item', isNavMenuOpened ? '' : 'mb-3']"
    >
      <div class="bao-nav-item w-100 btn-bao-user d-flex align-items-center justify-content-center">
        <!-- Note: The click event is required to check if this component was clicked in MainApp.vue -->
        <b-dropdown
          variant="secondary"
          :class="isNavMenuOpened ? 'w-100 account-dropdown-button menu-open' : ''"
          toggle-class="p-0 nav-account-button"
          menu-class="nav-account-menu"
          no-caret
          no-flip
          @show="$emit('click')"
        >
          <template slot="button-content">
            <div
              id="profile-label-202012241018"
              class="d-flex justify-content-between w-100"
            >

              <div class="my-auto">
                <img src="../../../public/img/icons/user-icon.svg"
                     class="user-icon m-0"
                />
                <span v-if="user && isNavMenuOpened"
                      class="my-auto ml-2"
                >
                  {{ getUsername }}
                </span>
              </div>

              <span v-if="isNavMenuOpened"
                    class="my-auto"
              >
                <i class="float-right ml-1 fas fa-chevron-right text-primary chevron"></i>
              </span>

            </div>
            <b-tooltip
              :title="staticText.profile"
              :disabled="isNavMenuOpened"
              :delay="0"
              target="profile-label-202012241018"
              placement="right"
            ></b-tooltip>
          </template>

          <div v-if="isGlobalAdmin">
            <!-- TODO: Find an icon and adjust styling -->
            <b-dropdown-item id="admin-page-202012241018"
                             class="bao-dropdown-item"
                             to="/admin"
                             @click="openAdminPage"
            >
              <img src="../../../public/img/icons/settings-icon.svg"
                   class="mr-1 py-2"
              />
              {{ staticText.adminLabel }}

            </b-dropdown-item>
            <div class="d-flex justify-content-center">
              <div class="border-bottom w-75"></div>
            </div>
          </div>
          <b-dropdown-item id="profile-page-202012241018"
                           class="bao-dropdown-item"
                           to="/users/profile"
          >
            <img src="../../../public/img/icons/user-icon.svg"
                 class="mr-1 py-2"
            />
            {{ staticText.userLabel }}

          </b-dropdown-item>
          <div class="d-flex justify-content-center">
            <div class="border-bottom w-75"></div>
          </div>
          <b-dropdown-item id="integrations-page-202012241018"
                           class="bao-dropdown-item"
                           to="/users/integrations"
          >
            <img src="../../../public/img/icons/integration-icon.svg"
                 class="mr-1 py-2"
            />
            {{ staticText.integrations }}
          </b-dropdown-item>
          <div class="d-flex justify-content-center">
            <div class="border-bottom w-75"></div>
          </div>
          <b-dropdown-item id="team-page-202012241018"
                           class="bao-dropdown-item"
                           to="/users/team"
          >
            <img src="../../../public/img/icons/sidebar-team-icon.svg"
                 class="mr-1 py-2"
            />
            {{ staticText.team }}
          </b-dropdown-item>
          <div class="d-flex justify-content-center">
            <div class="border-bottom w-75"></div>
          </div>
          <b-dropdown-item id="sign-out-202012241018"
                           class="bao-dropdown-item"
                           @click="displaySignoutModal=true"
          >
            <img src="../../../public/img/icons/logout-icon.svg"
                 class="mr-1 py-2"
            />
            {{ staticText.logout }}
          </b-dropdown-item>

        </b-dropdown>
      </div>

      <div id="signout-modal-180120211557"
           v-if="displaySignoutModal"
      >
        <b-modal v-model="displaySignoutModal"
                 size="sm"
                 centered
                 hide-footer
        >
          <template v-slot:modal-header-close>
            <img src="../../../public/img/icons/close-icon.svg"
                 class="pb-1"
            />
          </template>
          <template v-slot:modal-title>
            <img src="../../../public/img/icons/small-lock-icon.svg"
                 class="mr-2 pb-1"
            />
            <span>{{ staticText.logout }}</span>
          </template>
          <signout-modal @cancelled="displaySignoutModal = false"></signout-modal>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import SignoutModal from "./SignoutModal"
import MainMenuHelpButton from "./MainMenuHelpButton"
import { mapActions, mapGetters } from "vuex"
import ErrorModal from "./ErrorModal"
import { setSchemaName, setTokenPair, conditionalForceLogout } from "@/utils/authenticationManager"
import Treeselect from "@riophae/vue-treeselect"
import { treeSelectUtils } from "@/apps/user/TeamManagementComponents/TeamManagementUtils"

export default {
  name: "MainMenuUserBar",
  components: {
    MainMenuHelpButton,
    SignoutModal,
    ErrorModal,
    Treeselect
  },
  props: {
    isNavMenuOpened: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      displaySignoutModal: false,
      selectedTenant: null,
      errors: null,
      staticTextDefault: {
        profile: "Profile",
        userLabel: "User",
        adminLabel: "Admin",
        logout: "Logout",
        integrations: "Integrations",
        helpLabel: "Help",
        team: "Team"
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      getCrmIntegrationErrors: "auth/getCrmIntegrationErrors"
    }),
    getUsername () {
      const maxLength = 17
      const fullName = this.user.full_name
      if (fullName.length > maxLength) {
        return fullName.substr(0, maxLength) + "..."
      }
      return fullName
    },
    isGlobalAdmin () {
      return this.user && this.user.permissions && this.user.permissions.is_global_admin
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    selectedTenant (val, oldVal) {
      if (!!val && val !== oldVal) {
        this.switchTenant(val)
      }
    }
  },
  mounted () {
    this.setSelectedTenant()
    this.updateErrors()
    this.checkForceLogout()
  },
  methods: {
    ...treeSelectUtils.methods,
    ...mapActions({
      getUser: "auth/getUser",
      getCRMIntegrationsForUser: "auth/getCrmIntegrationsForUser"
    }),
    switchTenant (newTenant) {
      axios.post("/api/users/change_tenant", { schema_name: newTenant.schema_name })
        .then(({ data }) => {
          const { access, refresh } = data
          setTokenPair(access, refresh)
          setSchemaName(data.schema_name)
        })
        .then(() => this.getUser())
        .catch(error => console.log(error))
    },
    setSelectedTenant () {
      if (!this.user || !this.user.permissions || !this.user.permissions.tenants) return
      for (const tenant of this.user.permissions.tenants) {
        const selectedTenant = this.searchTree(tenant)
        if (selectedTenant) {
          this.selectedTenant = selectedTenant
        }
      }
    },
    openAdminPage () {
      window.open("/admin", "_blank").focus()
    },
    checkForceLogout () {
      conditionalForceLogout()
    },
    searchTree (tenant) {
      if (tenant.selected) {
        return tenant
      } else if (tenant.children.length > 0) {
        for (const child of tenant.children) {
          const result = this.searchTree(child)
          if (result) return result
        }
        return null
      }
      return null
    },
    updateErrors () {
      try {
        this.getCRMIntegrationsForUser().then(() => {
          this.errors = this.getCrmIntegrationErrors
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.h-15px {
  height: 15px;
}

.user-icon, .user-icon-with-avatar {
  width: 46px;
  height: 46px;
  background-color: $white;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  border-radius: 14px;
  opacity: 0.8;
  margin-left: 11px;
}

.user-icon {
  padding: 14px;
}

.chevron {
  padding-right: 18px;
}

.collapsed {
  .vue-treeselect--focused {
    // This is the width when it's expanded, we want to maintain this even when
    // navBar is closed and user wants to change tenants
    width: 230px !important;
  }
}

</style>
