<template>
  <div>
    <div id="help-btn-202012241053"
         class="bao-nav-item"
         @click.prevent="toggleHelpModal"
    >
      <slot></slot>
    </div>

    <b-modal v-model="showHelpModal"
             dialog-class="help-modal"
             body-class="help-modal-body"
             footer-class="pt-0 pb-4 border-0"
             footer-border-variant="none"
             centered
    >
      <template v-slot:modal-header-close>
        <img src="../../../public/img/icons/close-icon.svg"
             class="pb-1"
        />
      </template>
      <template v-slot:modal-title>
        <img src="../../../public/img/icons/help-icon.svg"
             class="mr-2"
             style="width: 16px;"
        />
        <span id="help-modal-title-text-202102192014">
          {{ staticText.titleText }}
        </span>
      </template>

      <div class="container text-center">

        <div id="help-modal-main-text-202102192018">
          <h4 class="help-modal-title font-weight-bold">
            <div v-html="staticText.mainText"></div>
          </h4>
          <p v-html="staticText.secondText"></p>
        </div>

        <!-- help yourself section -->
        <section class="row mt-3 mb-2">
          <div class="col-1">
            <i class="fas fa-desktop"></i>
          </div>

          <div class="ml-2 font-weight-bold">
            {{ staticText.helpYourselfText }}:
          </div>

          <div class="col text-right">
            <a
              href="https://www.bao.ai/learning-and-support/"
              target="_blank"
            >
              <u>
                Learning Center
              </u>
            </a>
          </div>
        </section>
        <div class="border"></div>

        <!-- write us section -->
        <section class="row mt-3 mb-2">
          <div class="col-1">
            <img src="../../../public/img/icons/mail-icon.svg" />
          </div>

          <div class="ml-2 font-weight-bold">
            {{ staticText.writeUsText }}:
          </div>

          <div class="col text-right">
            <a :href="`mailto:${customerSupportEmail}`">
              <u>
                {{ customerSupportEmail }}
              </u>
            </a>
          </div>
        </section>
        <div class="border"></div>

        <!-- call us section -->
        <section class="row mt-3">
          <div class="col-1">
            <img src="../../../public/img/icons/call-icon.svg" />
          </div>

          <div class="ml-2 font-weight-bold">
            {{ staticText.callUsText }}:
          </div>

          <div class="col text-right">
            <u><a href="tel:+498969313573 ">
              +49 89 6931 3573
            </a></u>
          </div>
        </section>
        <div class="border mt-2"></div>
      </div>

      <template #modal-footer="{ ok }">
        <b-btn size="sm"
               class="modal-close-btn"
               variant="secondary"
               @click="ok()"
        >
          {{ staticText.closeBtnText }}
        </b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "MainMenuHelpButton",
  data () {
    return {
      staticTextDefault: {
        titleText: "Help",
        mainText: "You need help or you have a question?",
        secondText: "Take a look at our learning platform or reach out to our Customer Success Team!",
        writeUsText: "Write Us",
        helpYourselfText: "Help Yourself",
        callUsText: "Call Us",
        closeBtnText: "Close"
      },
      showHelpModal: false,
      customerSupportEmail: "customer@bao-solutions.com"
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  methods: {
    toggleHelpModal () {
      this.showHelpModal = !this.showHelpModal
      const label = document && document.location ? document.location.href : "Unknown location"
      this.$ga.event("help", "click", label, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.help-modal-title {
  color: $slate;
}

.book-call-btn {
  font-size: 14px;
  padding: 12px 25px;
}

.modal-close-btn {
  padding: 12px 24px;
}

.border {
  border: 1px solid #DAD8DB !important;
  opacity: 0.4;
}
</style>
