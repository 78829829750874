<template>
  <div id="error-div-202012241230">

    <b-alert
      variant="danger"
      dismissible
      :show="!!error"
      class="m-0"
      @dismissed="clearError"
    >
      <div id="error-202012241230">{{error}}</div>
    </b-alert>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"

export default {
  name: "GlobalErrorToast",
  computed: {
    ...mapState("globalError", ["error"]),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...mapMutations({
      clearError: "globalError/clearError"
    })
  }
}
</script>

<style scoped lang="scss">

</style>
