<template>
  <div class="input-wrapper mb-2">
    <b-form-input v-model="searchTerm"
                  :placeholder="getPlaceholder"
                  class="pr-5"
    ></b-form-input>
    <img src="/img/icons/search-icon.svg"
         class="search-icon"
    />
  </div>

</template>

<script>

export default {
  name: "BaseSearch",
  props: {
    value: {
      type: String,
      default: null,
      required: true
    },
    placeholder: {
      type: String,
      default: "",
      required: false
    }
  },
  data () {
    return {
      staticTextDefault: {
        searchPlaceholder: "Search"
      },
      searchTerm: ""
    }
  },
  computed: {
    getPlaceholder () {
      return this.placeholder || this.staticText.searchPlaceholder
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    searchTerm (newVal) {
      if (newVal !== this.value) {
        this.$emit("input", newVal)
      }
    },
    value: {
      handler (val) {
        this.searchTerm = val
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

.search-icon {
    color: rgba(42,45,82,.04);
    position: absolute;
    top: 10px;
    right: 20px;
}

</style>
