<template>
  <div id="dashboard-menu-202209083215"
       :class="['dashboard-menu','menu-slide-transition', showMenu? 'openedMenu' : '']"
  >
    <!-- Heading -->
    <div class="dashboard-menu__title">
      {{staticText.dashboards}}
    </div>

    <!-- Search bar with add button -->
    <div class="dashboard-menu__search">
      <base-search v-model="widgetPageTypeFilter"
                   :placeholder="staticText.searchPlaceholder"
                   class="search"
      ></base-search>

      <img id="menu-create-dashboard-btn-20220709"
           src="/img/icons/light-plus-icon.svg"
           class="add-widget-icon"
           @click="create"
      />
      <b-tooltip id="tooltip-menu-create-dashboard-btn-20220709"
                 target="menu-create-dashboard-btn-20220709"
                 placement="bottom"
                 noninteractive
      >
        {{ staticText.newDashboard }}
      </b-tooltip>
    </div>

    <!-- Content -->
    <div class="dashboard-menu__content">

      <progress-indicator
        v-if="loading || searching"
        :show-loading-label="false"
        :small="true"
        class="p-4"
      ></progress-indicator>

      <div v-else
           class="dashboard-menu__content__data"
      >
        <!-- Favorite dashboards list -->
        <base-dashboard-list
          :items="favoriteDashboardsFiltered"
          :is-favorite-list="true"
          @selected="updateRoute"
          @deleted="handleDashboardDeleted"
          @favorite-icon-clicked="toggleFavorite"
        >
          <template #title>
            <div class="d-flex align-items-center">
              <img src="/img/icons/star.svg"
                   class="favorite-title-icon mr-1 my-auto"
              />
              <span>
                {{staticText.favorites}}
              </span>
            </div>
          </template>

          <template #no-data>
            {{staticText.favoriteDashboardsUnavailable}}
          </template>
        </base-dashboard-list>

        <!-- My dashboards list -->
        <base-dashboard-list
          :title="staticText.myDashboards"
          :items="myDashboardsFiltered"
          @selected="updateRoute"
          @deleted="handleDashboardDeleted"
          @favorite-icon-clicked="toggleFavorite"
        >
          <template #no-data>
            {{staticText.myDashboardsUnavailable}}
          </template>
        </base-dashboard-list>

        <!-- Shared dashboards list -->
        <base-dashboard-list
          :title="staticText.sharedDashboards"
          :items="sharedDashboardsFiltered"
          :disable-delete="!currentUserIsAdminOrSuperuser"
          @selected="updateRoute"
          @favorite-icon-clicked="toggleFavorite"
          @deleted="handleDashboardDeleted"
        >
          <template #no-data>
            {{staticText.sharedDashboardsUnavailable}}
          </template>
        </base-dashboard-list>

      </div>
    </div>
    <hr>
    <div class="dashboard-menu__footer"
         @click="updateRoute({id:'playbookanalytics'})"
    >
      <img src="/img/icons/bar-chart.svg"
           class="mr-2 playbook-analysis-icon"
      />
      <span>{{ staticText.playbookAnalytics }}</span>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import BaseSearch from "@/apps/base/BaseSearch"
import { baseUrl, dashboardStoreUtils } from "@/apps/dashboard/index"
import baseDashboardList from "@/apps/dashboard/BaoDashboardComponents/BaseDashboardList"

export default {
  name: "BaoDashboardMenu",
  components: {
    baseDashboardList,
    ProgressIndicator,
    BaseSearch
  },
  data () {
    return {
      dashboardStoreUtils,
      staticTextDefault: {
        dashboards: "Dashboards",
        searchPlaceholder: "Search for dashboards",
        playbookAnalytics: "Playbook Analytics",
        newDashboard: "New Dashboard",
        favorites: "Favorites",
        favoriteDashboardsUnavailable: "You have no favorite dashboards yet. Click the star-icon to mark dashboards as your favorites.",
        myDashboards: "My Dashboards",
        myDashboardsUnavailable: "No own dashboards available to be displayed. Add new dashboards to display them here.",
        sharedDashboards: "Shared Dashboards",
        sharedDashboardsUnavailable: "No shared dashboards available to be displayed. Ask your colleagues to share dashboards with you."
      },
      widgetPageTypeFilter: "",
      searching: false
    }
  },
  computed: {
    ...mapGetters({
      loading: "dashboardStore/getLoadingAnalyticsPages",
      getDefaultDashboard: "dashboardStore/getDefaultDashboard",
      showMenu: "dashboardStore/showMenu",
      loadedDashboardsOnce: "dashboardStore/loadedDashboardsOnce",
      currentPageId: "dashboardStore/getCurrentPageId",
      favoriteDashboards: "dashboardStore/favoriteDashboards",
      myDashboards: "dashboardStore/myDashboards",
      sharedDashboards: "dashboardStore/sharedDashboards",
      getLoadedAnalyticsPages: "dashboardStore/getLoadedAnalyticsPages",
      currentUserIsAdminOrSuperuser: "auth/isAdminOrSuperUser"
    }),
    isDashboardRoute () {
      return this.$route.path.startsWith(baseUrl)
    },
    filterRegex () {
      return new RegExp(this.widgetPageTypeFilter, "i")
    },
    favoriteDashboardsFiltered () {
      return this.favoriteDashboards.filter(item => this.filterRegex.test(item.name))
    },
    sharedDashboardsFiltered () {
      return this.sharedDashboards.filter(item => this.filterRegex.test(item.name))
    },
    myDashboardsFiltered () {
      return this.myDashboards.filter(item => this.filterRegex.test(item.name))
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    showMenu (newVal) {
      if (!newVal) {
        this.widgetPageTypeFilter = ""
      }
    }
  },
  mounted () {
    this.setUp()
  },
  methods: {
    ...mapMutations({
      deletePage: "dashboardStore/deletePage"
    }),
    ...mapActions({
      loadAnalyticsPages: "dashboardStore/loadAnalyticsPages",
      createDashboard: "dashboardStore/createDashboard",
      toggleFavorite: "dashboardStore/toggleFavorite"
    }),
    setUp () {
      // if dashboards were already loaded once(when baoDashboard is mounted) then do not reload it
      if (!this.loadedDashboardsOnce) this.loadAnalyticsPages()
    },
    handleDashboardDeleted (page) {
      this.deletePage(page.id)
      // only if current route is dashboard route and page being deleted is the current displayed page on the screen, then show default page
      if (this.isDashboardRoute && this.currentPageId === page.id) {
        this.updateRoute(this.getDefaultDashboard, false)
      }
    },
    create () {
      const newDashboardTitle = dashboardStoreUtils.getNewDashboardTitle(this.getLoadedAnalyticsPages)
      return this.createDashboard({ name: newDashboardTitle }).then((newPage) => {
        this.updateRoute(newPage)
      })
    },
    updateRoute (page, hideDashboard = true) {
      if (hideDashboard) {
        this.mixinHideDashboardMenu()
      }
      const url = page ? `${baseUrl}/${page.id}` : baseUrl
      // if the url is same as current route then do not reset as it results in navigation duplication error
      if (url === this.$route.path) return
      this.$router.push(url)
    }
  }
}
</script>

<style scoped lang="scss">

.dashboard-menu {
  background-color: $white;
  width: 384px;
  z-index: 9999;
  position: absolute;
  margin: 8px;
  height: 98vh;
  border-radius: 20px;
  padding: 28px 28px 0px 28px;
  box-shadow: 6px 8px 20px rgb(179 173 159 / 24%);
}

.dashboard-menu__title{
  font-size: 20px;
  color: $slate;
  font-weight: 700;
  line-height: 22px;
  height: 22px ;
  margin-bottom: 8px;
}

.dashboard-menu__search{
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin: 2px -16px;
}

.dashboard-menu__content__data{
  margin: 0 16px;
}

.dashboard-menu__content{
  overflow-y: auto;
  height: calc(100% - 137px);
  margin: 8px -16px;
}

.dashboard-menu__footer{
  height: 55px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $slate80;
}

.playbook-analysis-icon{
  height: 14.4px;
  width: 14.4px;
}

hr{
  margin: 0 -28px;
  border-top: 1px solid $slate06;
}

.add-widget-icon{
  cursor: pointer;
  margin: auto 0;
  height: 32px;
  width: 32px;
  background-color: $slate80;
  box-shadow: 16px 12px 40px $black05;
  padding: 9px;
  border-radius: 20px;
  &:hover{
    background-color: $slate;
  }
}

.search{
  width: 88%
}

.menu-slide-transition{
  left: -1000px;  /* Note: This is used in the handleClickedOutside to check if the div is currently shown on screen*/
  transition: 0.3s ease-in;
  &.openedMenu{
    left: 0
  }
}

.favorite-title-icon {
  height: 14.4px;
  width: 14.4px;
}

</style>

<style>

.dashboard-menu__search .form-control{
  height: 36px !important;
}
</style>
