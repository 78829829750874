<template>
  <div class="dashboard-menu-item-group">
    <div class="dashboard-menu-item-group__title">
      <slot name="title">
        {{title}}
      </slot>
    </div>
    <div v-if="items.length<=0"
         class="dashboard-menu-item-group__no-data"
    >
      <slot name="no-data"></slot>
    </div>

    <div v-else
         v-for="(item, index) in items"
         :key="index+'-'+item.id"
         class="dashboard-menu-item-group__item"
         :class="getActiveClass(item)"
         @click.stop="handleItemClicked(item)"
    >
      <span class="dashboard-menu-item-group__item__name">
        {{item.name}}
      </span>

      <div class="d-flex align-items-center">
        <img :id="'fav-dashboard-20220919147-'+index+'-'+item.id"
             src="/img/icons/star.svg"
             :class="!isFavoriteList?'not-favorite':'favorite'"
             class="favorite-icon mr-2"
             @click.stop="handleFavoriteIconClicked(item)"
        />

        <b-tooltip
          :id="'fav-dashboard-tooltip-20220919147-'+index+'-'+item.id"
          :target="'fav-dashboard-20220919147-'+index+'-'+item.id"
          noninteractive
          placement="bottom"
        >
          {{getFavoriteTooltip}}
        </b-tooltip>

        <bao-delete-button
          :id="'delete-dashboard-20220919147-'+index+'-'+item.id"
          :extra-class="getDeleteBtnClass(item)"
          :url="getDeleteUrl(item)"
          :tooltip="getDeleteTooltip(item)"
          :show-confirm="canDelete(item)"
          :noninteractive-tooltip="true"
          tooltip-placement="bottom"
          size="sm"
          @deleted="handleItemDeleted(item)"
        >
        </bao-delete-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"

export default {
  name: "BaseDashboardList",
  components: {
    BaoDeleteButton
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    disableDelete: {
      type: Boolean,
      required: false,
      default: false
    },
    isFavoriteList: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      staticTextDefault: {
        deleteTooltip: "Delete Dashboard",
        disabledDeleteTooltip: "Shared Dashboards can’t be deleted",
        favoriteTooltip: "Add to Favorites",
        removeFavoriteTooltip: "Remove from Favorites"
      }
    }
  },
  computed: {
    ...mapGetters({
      currentPageId: "dashboardStore/getCurrentPageId",
      isUserCreator: "dashboardStore/isUserCreator",
      currentUserIsAdminOrSuperuser: "auth/isAdminOrSuperUser"
    }),
    getFavoriteTooltip () {
      return this.isFavoriteList ? this.staticText.removeFavoriteTooltip : this.staticText.favoriteTooltip
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    canDelete (page) {
      return !this.disableDelete && (this.isUserCreator(page) || this.currentUserIsAdminOrSuperuser)
    },
    getDeleteTooltip (page) {
      return this.canDelete(page) ? this.staticText.deleteTooltip : this.staticText.disabledDeleteTooltip
    },
    handleItemClicked (item) {
      this.$emit("selected", item)
    },
    handleItemDeleted (item) {
      this.$emit("deleted", item)
    },
    getActiveClass (item) {
      return item.id === this.currentPageId ? "active" : ""
    },
    handleFavoriteIconClicked (item) {
      this.$emit("favorite-icon-clicked", item)
    },
    getDeleteUrl (item) {
      return this.canDelete(item) ? "/api/widgetpages/" + item.id : null
    },
    getDeleteBtnClass (item) {
      let btnClass = "delete-icon "
      btnClass += !this.canDelete(item) ? "inactive" : ""
      return btnClass
    }
  }
}
</script>

<style scoped lang="scss">

.dashboard-menu-item-group {
  padding: 15px 0;

  &__title{
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: $slate;
  }

  &__no-data{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $slate60;
  }

  &__item{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $slate80;
    margin: 0px -10px 4px;
    padding:10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in;
    border-radius: 12px;
    background-color: transparent;
    &:hover{
      background-color: $slate06;
      color: $slate;
      .favorite-icon {
        visibility: visible;
        &.favorite {
          opacity: 1;
        }
        &.not-favorite{
          opacity: 0.4;
        }
        &:hover{
          opacity: 1.0;
        }
      }

      :deep(.delete-icon) {
        visibility: visible;
        opacity: 0.4;
        &:hover {
          opacity: 1;
          &.inactive {
            opacity: 0.4 !important;
            cursor: default;
          }
        }
      }
    }
    &.active{
      background-color: $slate08;
      color: $slate;
    }

    &__name{
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
      white-space: nowrap;
    }
    .favorite-icon{
      visibility: hidden;
      opacity: 0;
      height: 14.4px;
      width: 14.4px;
      transition: 0.3s ease-in;
    }

    :deep(.delete-icon) {
      display: flex;
      align-items: center;
      padding: 0;
      visibility: hidden;
      opacity: 0;
      height: 14.4px;
      width: 12.6px;
      transition: 0.3s ease-in;
    }
  }
}

</style>
