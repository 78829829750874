<template>
  <div id="bcs-main-container-202012311306">
    <global-toast />
    <div class="wrapper vh-100 ">
      <nav id="sidebar-202012311306"
           :class="['bao-sidebar d-none d-xl-flex flex-column justify-content-between', {'collapsed hidden' : !showNavbar}]"
      >
        <bcs-nav-bar :isNavMenuOpened="showNavbar" :show-close-btn="showCloseBtn">
          <template slot="close-btn">
            <div class="closeBtnParent">
              <b-btn id="close-btn-202012311306"
                     class="closeBtn"
                     variant="secondary"
                     @click="toggleNavbar"
              >
                <i
                  class="closeBtnIcon"
                  :class="showNavbar ? 'fas fa-angle-left' : 'fas fa-angle-right'"
                ></i>
              </b-btn>
            </div>
          </template>
        </bcs-nav-bar>

        <main-menu-user-bar
          :isNavMenuOpened="showNavbar"
          @click="mixinHideDashboardMenu"
        ></main-menu-user-bar>
      </nav>

      <div class="main-content vh-100 scrollView nice-scrollbar">
        <div class="main-content__inner">
          <global-error-toast></global-error-toast>

          <div id="dashboard-menu-container">
            <!-- overlay is required to hide/show menu -->
            <div :class="['overlay', showDashboardMenu? 'displayedOverlay' : '']"
                 @click="mixinHideDashboardMenu"
            ></div>

            <bao-dashboard-menu></bao-dashboard-menu>
          </div>

          <div class="h-100 overflow-auto">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenuBar from "./base/MainMenuBar.vue"
import MainMenuUserBar from "./base/MainMenuUserBar"
import BaoDashboardMenu from "@/apps/dashboard/BaoDashboardMenu"
import GlobalErrorToast from "@/apps/base/GlobalErrorToast"
import GlobalToast from "@/apps/base/GlobalToast"
import { mapGetters, mapMutations } from "vuex"

export default {
  name: "MainApp",
  components: {
    MainMenuUserBar,
    bcsNavBar: MainMenuBar,
    GlobalErrorToast,
    GlobalToast,
    BaoDashboardMenu
  },
  data () {
    return {
      shownComponent: null,
      swRegistration: null,
      staticTextDefault: {
        appUpdate: "App Update",
        appUpdateText: "New features are available. Please press the button below to update to the newest version.",
        updateBtn: "Update"
      }
    }
  },
  computed: {
    ...mapGetters({
      showNavbar: "display/isMainMenuOpen",
      showCloseBtn: "display/isCloseBtnShown",
      showDashboardMenu: "dashboardStore/showMenu"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...mapMutations({
      toggleMainMenu: "display/changeMainMenu"
    }),
    toggleNavbar () {
      this.toggleMainMenu(!this.showNavbar)
    },
    hardReload () {
      const msg = "skipWaiting"
      this.swRegistration.postMessage({ action: msg })
    },
    showRefreshToast () {
      this.$bvToast.show("refToast")
    }
  }
}
</script>

<style scoped lang="scss">

.refreshToaster {
  position: absolute;
  left: 10px;
  bottom: 100px;
}

.main-content {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  padding: 0;
}

.scrollView {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.closeBtnParent {
  position: absolute;
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 236px;
  z-index: 99999;
  transition: all 0.3s;

  .closeBtn {
    width: 30px;
    height: 30px;
    color: $slate;
    background-color: $white;
    box-shadow: 6px 8px 20px 5px rgba(179, 173, 159, 0.24);
    border-radius: 20px;
    opacity: 0.4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:hover {
      opacity: 0.6;
    }
  }
}

.collapsed .closeBtnParent {
  height: 31px;
  margin-left: 60px;
}

.overlay{
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: transparent;
  visibility: hidden;
  transition: 0.3s ease-in;
  &.displayedOverlay{
    visibility: visible;
    background: linear-gradient(to right, transparent , $black30);
  }
}

.main-content__inner{
  position: relative;
  height: 100%;
  overflow: hidden;
}

</style>
